export const useStoryDefaultSettings = async () => {
  const { slug } = useRoute().params
  const urlPath = slug && slug.length > 0 ? slug.filter(Boolean).join('/') : ''
  const publicConfig = useRuntimeConfig().public
  const timestamp = useState('timestamp', () => +new Date())

  const defaultSettings = async (path) =>
    await useAsyncStoryblok(
      path,
      {
        version: publicConfig.storyblokVersion,
        cv: timestamp.value,
        resolve_relations: 'overview.featured_story'
      },
      {
        resolveRelations: 'overview.featured_story',
        resolveLinks: 'url',
        preventClicks: true
      }
    ).then((response) => {
      if (!response.value) {
        showError({
          statusCode: 404,
          message:
            'Es tut uns leid, aber die Seite, die Sie suchen, konnte leider nicht angezeigt werden.',
          statusMessage: 'Fehler - Es ist ein technisches Problem aufgetreten.'
        })
        clearError()
      } else {
        return response
      }
    })

  const storySettings = await useAsyncStoryblok(
    `product-domains/curabox/www/de/${urlPath}`,
    {
      version: publicConfig.storyblokVersion,
      cv: process.server ? +new Date() : null,
      resolve_relations: 'overview.featured_story'
    },
    {
      resolveRelations: 'overview.featured_story',
      resolveLinks: 'url',
      preventClicks: true
    }
  )

  return {
    defaultSettings,
    storySettings
  }
}
